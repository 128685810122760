/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Container from '../components/Container';
import Link from '../components/Link';

const Column = ({ className, heading, imgSrc, children }) => (
	<div
		className={`border border-neutral-200 p-6 my-6 md:my-8 lg:my-12 rounded-large md:flex md:items-start md:gap-8 ${
			className || ''
		}`}
	>
		<img src={imgSrc} alt="" className="block mx-auto" />
		<div>
			<h2 className="my-8 text-2xl font-bold leading-tight">{heading}</h2>
			{children}
		</div>
	</div>
);

const IndexPage = () => {
	const [isSurveyOpen, setIsSurveyOpen] = useState(false);

	const setSuveryOpen = () => {
		if (isSurveyOpen) {
			setIsSurveyOpen(false);
		} else {
			setIsSurveyOpen(true);
		}
		document.querySelector('.smcx-embed').classList.toggle('open');
		document.querySelector('body').classList.toggle('survey-open');
	};

	return (
		<Layout fullWidth="true">
			<button
				type="button"
				className={`close-button absolute top-2 left-2 text-white w-8 h-8 rounded-full ${
					isSurveyOpen ? 'opacity-100' : 'opacity-0'
				}`}
				onClick={setSuveryOpen}
			>
				X
			</button>
			<SEO title="Home" />
			<div className="bg-brighthr-gradient text-white">
				<Container className="py-6 md:py-8">
					<h1 className="mb-4 text-4xl font-bold leading-tight">
						Integrate with BrightHR
					</h1>
					<p className="mb-4 text-xl">
						We&apos;re at the early stages of building our public API, so would love to
						hear about your ideas for HR data integrations.
					</p>
					<Link linkType="button" onClick={setSuveryOpen}>
						Add your ideas
					</Link>
				</Container>
			</div>

			<Container>
				<Column
					heading="Access valuable HR data easily"
					imgSrc="../access-hr-data-easily.jpg"
				>
					<p className="text-xl my-4">
						Our upcoming HR platform&apos;s API will provide easy access to HR data to
						power your apps. Pull employee, job, salary, benefits, training, and more
						data through a well-documented API to build internal tools, analytics,
						recruiting solutions, and more.
					</p>
				</Column>
				<Column heading="Save significant development time" imgSrc="../save-dev-time.jpg">
					<p className="text-xl my-4">
						Our upcoming API will eliminate the need for complex integrations and
						pipelines. Get direct access to clean, normalised HR data without building
						custom pipelines.
					</p>
					<p className="text-xl">
						Save significant time and resources by accessing up-to-date, well-organised
						HR data with just a few lines of code.
					</p>
				</Column>
				<Column heading="Build dynamic applications" imgSrc="../build-dynamic-apps.jpg">
					<p className="text-xl my-4">
						Our upcoming API will enable building highly dynamic HR apps by seamlessly
						integrating our real-time HR data. Build employee portals with real-time
						profiles, recruiting sites with API-served job listings, analytics apps with
						interactive dashboards of HR metrics, and more.
					</p>
					<p className="text-xl">
						A robust platform API providing on-demand data will allow endless
						possibilities.
					</p>
				</Column>
			</Container>
			<Container className="mb-8 mt-12">
				<a
					href="https://www.brighthr.com/terms"
					className="text-primary-700 hover:text-primary-800"
				>
					Developer Agreement, Policy &amp; Terms
				</a>
			</Container>
		</Layout>
	);
};

export default IndexPage;
/* eslint-enable jsx-a11y/anchor-is-valid */
